import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { ArrowRightOutlined } from '@ant-design/icons';

import { createCustomer } from 'api/order_sdk';
import { Role } from 'api/types/auth';
import { useGuestCartDetail } from 'api/guest_sdk';

import { useAuth } from 'contexts/auth/auth';
import { Button } from 'ui-kit';
import { reverse } from 'utils/urls';

import { URLS } from 'components/Routes/urls';

export interface IPayNowButton {
  storeId: number;
}

const PayNowButton = ({ storeId }: IPayNowButton) => {
  const { role } = useAuth();

  const { data: cartData } = useGuestCartDetail({
    storeId,
  });

  const isAnonymousGuest = useMemo(() => role === Role.GUEST_ANONYMOUS, [role]);

  const isFormDisabled = useMemo(
    () => !isAnonymousGuest || cartData?.order_products?.length === 0,
    [isAnonymousGuest, cartData],
  );

  const history = useHistory();

  const nextStep = useCallback(() => {
    if (!_.isUndefined(cartData)) {
      createCustomer().then(({ id }) => {
        history.push(
          reverse({
            url: URLS.CHECKOUT,
            params: { storeId, orderId: cartData.id, customerId: id },
          }),
        );
      });
    }
  }, [storeId, cartData, history]);

  return (
    <Button
      onClick={nextStep}
      disabled={isFormDisabled}
      variant="contained"
      endIcon={<ArrowRightOutlined />}
    >
      Pay now
    </Button>
  );
};

export default PayNowButton;
