import { BASE_URL, get, post, useFetch, usePaginatedFetch } from 'utils/sdk';
import { EPurchaseCancelReason, PurchaseStatus } from './types/order';
import { Currency } from './types/store';
import _ from 'lodash';
import { formatGetParams } from 'utils/utilsV2';
import { ERefundType } from 'components/RefundButton/contants';
import { EDateFilter } from 'pages/OrdersList/constants';
import { ProductCategory } from './types/product';

export interface IOrderProduct {
  id: number;
  product_name: string;
  quantity: number;
  delivery_date: string;
  price: string;
  currency: Currency;
  amount_to_refund?: string; // This is the amount that will be refunded - the price + commission
  non_refundable_reason?: string;
  is_refunded?: boolean;
  status?: PurchaseStatus;
  owner_purchase_uuid?: string;
  cancel_reason?: EPurchaseCancelReason;
  convenience_fee: number;
  image?: string;
  category?: ProductCategory;
  note?: string;
  can_approve: boolean;
}
export interface IOrder {
  id: number;
  date_created: string;
  products: Array<IOrderProduct>;
  store: {
    id: number;
    store_name: string;
  };
  order_number: string;
  your_profit: string;
  grand_total: string;
  currency: Currency;
  guest_name: string;
  is_refundable: boolean;
  non_refundable_reason: string;
}

export const useOrders = ({
  storeId,
  status,
  dateFilter,
  startDate,
  endDate,
}: {
  storeId?: number;
  dateFilter?: EDateFilter;
  status?: PurchaseStatus;
  startDate?: string;
  endDate?: string;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, start_date: startDate, date_filter: dateFilter };
  }
  if (!_.isUndefined(endDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, end_date: endDate, date_filter: dateFilter };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return usePaginatedFetch<IOrder>({
    url: `services/order/list/`,
    params: { limit: 10, ...filters },
  });
};

export const exportOrders = ({
  storeId,
  status,
  startDate,
  endDate,
  dateFilter,
}: {
  storeId?: number;
  status?: PurchaseStatus;
  startDate?: string;
  endDate?: string;
  dateFilter?: EDateFilter;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, start_date: startDate, date_filter: dateFilter };
  }
  if (!_.isUndefined(endDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, end_date: endDate, date_filter: dateFilter };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return get<
    Array<{
      guest_name: string;
      date_created: string;
      order_number: string;
      total_amount: string;
      total_owner_amount: string;
      store_name: string;
    }>
  >(
    `${BASE_URL}services/order/export-orders/${formatGetParams({
      ...filters,
    })}`,
  );
};

export const exportOrderProducts = ({
  storeId,
  status,
  startDate,
  endDate,
  dateFilter,
}: {
  storeId?: number;
  status?: PurchaseStatus;
  startDate?: string;
  endDate?: string;
  dateFilter?: EDateFilter;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, start_date: startDate, date_filter: dateFilter };
  }
  if (!_.isUndefined(endDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, end_date: endDate, date_filter: dateFilter };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return get<
    Array<{
      guest_name: string;
      product_name: string;
      date_created: string;
      delivery_date: string;
      total_amount: string;
      your_profit: string;
      order_number: string;
      store_name: string;
    }>
  >(
    `${BASE_URL}services/order/export-orderproducts/${formatGetParams({
      ...filters,
    })}`,
  );
};

export const refundOrder = ({ orderId }: { orderId: number }) =>
  post(`${BASE_URL}services/order/${orderId}/refund/`, {});

export const refundOrderProduct = ({ orderProductId }: { orderProductId: number }) =>
  post(`${BASE_URL}services/orderproduct/${orderProductId}/refund/`, {});

export const getRefundStatus = ({
  refundType,
  refundRequestId,
}: {
  refundType: ERefundType;
  refundRequestId: number;
}) => {
  if (refundType === ERefundType.ORDER) {
    return get<{ is_refunded: boolean }>(
      `${BASE_URL}services/order/${refundRequestId}/is-refunded/`,
    );
  }

  return get<{ is_refunded: boolean }>(
    `${BASE_URL}services/orderproduct/${refundRequestId}/is-refunded/`,
  );
};

export const createCustomer = () =>
  post<{ id: string }>(`${BASE_URL}services/order/create-customer/`, {});

export const generateStripePaymentIntentClientSecret = ({
  orderId,
  customerId,
}: {
  orderId: number;
  customerId: string;
}) =>
  post<{ client_secret: string }>(`${BASE_URL}services/order/create-payment-intent/`, {
    order_id: orderId,
    customer_id: customerId,
  });

export const updateOrderProductQuantity = ({
  orderProductId,
  quantity,
}: {
  orderProductId: number;
  quantity: number;
}) =>
  post(`${BASE_URL}services/orderproduct/${orderProductId}/update-quantity/`, {
    count: quantity,
  });

export interface IOrderProductNeedApproval {
  product_name: string;
  store_name: string;
  guest_name: string;
  grand_total: string;
  total_owner_amount: string;
  owner_purchase_uuid: string;
  currency: Currency;
}

export const useOrderProductNeedApproval = ({ ownerId }: { ownerId: number }) =>
  useFetch<Array<IOrderProductNeedApproval>>(
    `services/orderproduct/owner/${ownerId}/need-approval/`,
  );
