import React from 'react';
import { Checkbox } from 'antd';
import { Link } from 'ui-kit';
import './styles.scss';

export interface IAgreement {
  onChange: (checked: boolean) => void;
}

const Agreement = ({ onChange }: IAgreement) => {
  return (
    <div className="CheckoutForm_customerFormAgreement">
      <div className="CheckoutForm_checkoutFormCheckboxWrapper">
        <Checkbox onChange={(e) => onChange(e.target.checked)} />
      </div>
      <div>
        By clicking “Place your order” you agree to The Host Co{' '}
        <Link target="_blank" href="https://www.thehost.co/terms-conditions">
          terms of service
        </Link>{' '}
        and{' '}
        <Link target="_blank" href="https://www.thehost.co/privacy-policy">
          privacy policy
        </Link>{' '}
        and understand that local vendor items may include a Host Co concierge fee in total price.
        Transaction will appear on your card as The Host Co or The Host Company HC Inc., and may
        include the terms Web or Software Service.
      </div>
    </div>
  );
};

export default Agreement;
