import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, message } from 'antd';
import { Button, Card, CardContent, InputHelperText, Spinner, TextField, Typography } from 'ui-kit';

import { ActorType, resendGuestReceiptEmail, usePurchaseDetails } from 'api/purchase_sdk';

import OverviewLayout from 'components/OverviewLayout/OverviewLayout';
import PurchaseDetailsTable from 'components/PurchaseDetails/PurchaseDetailsTable';

import './styles.scss';

const PurchaseDetails = () => {
  const { purchaseId } = useParams<{ purchaseId: string }>();
  const { data: purchaseDetails, isLoading } = usePurchaseDetails({
    purchaseId: Number(purchaseId),
  });

  const [guestEmail, setGuestEmail] = useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isResending, setIsResending] = useState<boolean>(false);

  useEffect(() => {
    if (purchaseDetails?.guest_email) {
      setGuestEmail(purchaseDetails.guest_email);
    }
  }, [purchaseDetails]);

  const handleButtonClick = useCallback(() => {
    setIsResending(true);
    setIsButtonDisabled(true);
    resendGuestReceiptEmail({ purchaseId: purchaseDetails.id, guestEmail }).then((res) => {
      if (!res) {
        message.error('Something went wrong. Please try again.');
        setIsButtonDisabled(false);
      } else {
        message.success('Receipt sent!');
      }
      setIsResending(false);
    });
  }, [guestEmail, purchaseDetails]);

  if (isLoading || !purchaseDetails) {
    return (
      <OverviewLayout>
        <Spinner />
      </OverviewLayout>
    );
  }

  return (
    <OverviewLayout>
      <Card className="OrderDetailPage_content">
        <CardContent>
          <div className="OrderDetailPage_content-title">
            <Typography variant="h6">Order #{purchaseDetails.order_number}</Typography>
            <Divider className="OrderDetailPage_content-title-divider" />
          </div>
          <div className="OrderDetails">
            <PurchaseDetailsTable
              purchase={purchaseDetails}
              actorType={ActorType.OWNER}
              showPrices
            />
            <div className="OrderDetails__resend-email">
              <InputHelperText>
                If you need to resend the guest&apos;s receipt or send it to a different email,
                enter it here.
              </InputHelperText>
              <div className="OrderDetails__resend-email-input-row">
                <TextField
                  type="email"
                  value={guestEmail}
                  onChange={(e) => setGuestEmail(e.target.value)}
                />
                <Button
                  disabled={isResending || isButtonDisabled}
                  variant="outlined"
                  onClick={handleButtonClick}
                  className="OrderDetails__resend-email-button"
                >
                  {isResending && <Spinner />}
                  {!isResending && isButtonDisabled && 'Sent!'}
                  {!isResending && !isButtonDisabled && 'Resend Receipt'}
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </OverviewLayout>
  );
};

export default PurchaseDetails;
