import { BASE_URL, get, post, useFetch } from 'utils/sdk';
import _ from 'lodash';
import { PurchaseStatus } from './types/order';

export interface IPurchaseDetail {
  id: number;
  order_number: string;
  store_name: string;
  currency: string;
  status: PurchaseStatus;
  approval_note: string;

  guest: string;
  guest_email: string;
  guest_phone: string;
  guest_amount: number;

  owner_name?: string;
  owner_plan?: string;
  owner_amount?: number;

  merchant_amount?: number;

  order_products: Array<{
    id: number;
    product_name: string;
    quantity: number;
    price: number;
    delivery_date: Date;
    note: string;
    product_info: {
      image: string;
    };
    extra_field_values?: Array<{
      label: string;
      value: string;
    }>;
  }>;
}

export enum ActorType {
  GUEST = 'guest',
  OWNER = 'owner',
  MERCHANT = 'merchant',
}

export const getPurchaseByActorUUID = ({
  actorType,
  uuid,
}: {
  actorType: ActorType;
  uuid: string;
}) => {
  if (actorType === ActorType.OWNER) {
    return get<IPurchaseDetail>(`${BASE_URL}services/purchase/owner/${uuid}/`);
  }
  if (actorType === ActorType.MERCHANT) {
    return get<IPurchaseDetail>(`${BASE_URL}services/purchase/merchant/${uuid}/`);
  }
  if (actorType === ActorType.GUEST) {
    return get<IPurchaseDetail>(`${BASE_URL}services/purchase/guest/${uuid}/`);
  }
};

export const approveOnHoldPurchase = ({
  actorType,
  uuid,
  approvalNote,
}: {
  actorType: ActorType;
  uuid: string;
  approvalNote: string;
}) =>
  post(`${BASE_URL}services/purchase/${actorType}/${uuid}/approve/`, {
    approval_note: approvalNote,
  });

export const cancelOnHoldPurchase = ({
  actorType,
  uuid,
  approvalNote,
}: {
  actorType: ActorType;
  uuid: string;
  approvalNote: string;
}) => {
  if (actorType === ActorType.GUEST) {
    return post(`${BASE_URL}services/purchase/guest/${uuid}/cancel/`);
  } else {
    return post(`${BASE_URL}services/purchase/${actorType}/${uuid}/deny/`, {
      approval_note: approvalNote,
    });
  }
};

export const usePurchaseDetails = ({ purchaseId }: { purchaseId: number }) =>
  useFetch<IPurchaseDetail>(`${BASE_URL}services/purchase/${purchaseId}}/detail/`);

export const resendGuestReceiptEmail = ({
  purchaseId,
  guestEmail,
}: {
  purchaseId: number;
  guestEmail: string;
}) =>
  post(`${BASE_URL}services/purchase/${purchaseId}/resend-guest-email/`, {
    guest_email: guestEmail,
  });
