import React, { useEffect } from 'react';
import { Typography } from 'antd';
import Layout from 'components/StoreLayout';
import './styles.scss';
import { Button, Link } from 'ui-kit';
import { useParams } from 'react-router-dom';

const SuccessCheckoutPage = () => {
  const { storeId, orderId, guestId } = useParams<{
    storeId: string;
    orderId: string;
    guestId: string;
  }>();

  // TODO: I'm not sure what this is doing, but is the old feature and just copied it here
  useEffect(() => {
    window.fbq?.('track', 'Purchase', null, { eventID: `${orderId}-${guestId}` });
  }, []);

  return (
    <div className="StoreCheckoutSuccess">
      <Layout className="">
        <Layout.Content className="StoreCheckoutSuccess_layout">
          <div className="StoreCheckoutSuccess_content">
            <Typography.Title className="StoreCheckoutSuccess_title" level={1}>
              Thank you!
            </Typography.Title>
            <Typography.Paragraph className="StoreCheckoutSuccess_text">
              A message with instructions has been sent to your email. If you have not received it,
              please check your spam folder and email us if you need assistance.
              <br />
              <Link target="_blank" href="mailto:support@thehost.co">
                support@thehost.co
              </Link>
            </Typography.Paragraph>
            <Button variant="contained" to={`/store/${storeId}/`}>
              Back to store
            </Button>
          </div>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default SuccessCheckoutPage;
