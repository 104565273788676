import React, { useCallback, useState } from 'react';

import { useAuth } from 'contexts/auth/auth';
import {
  PaymentElement,
  useElements,
  useStripe,
  LinkAuthenticationElement,
} from '@stripe/react-stripe-js';
import { Button, Card, InputHelperText, TextField, Typography } from 'ui-kit';
import _ from 'lodash';
import { Col, message, Row } from 'antd';
import { ECheckoutFieldTypes, phoneRegExp } from './constants';

import './styles.scss';

import Agreement from 'components/Store/NewCheckout/Agreement';
import { reverse } from 'utils/urls';
import { URLS } from 'components/Routes/urls';
import { getHostname } from 'utils/utils';

export interface ICheckoutForm {
  storeId: number;
  orderId: number;
}

const CheckoutForm = ({ storeId, orderId }: ICheckoutForm) => {
  const { id: userId } = useAuth();

  const stripe = useStripe();
  const elements = useElements();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [acceptTermsAndCondictions, setHasAgreed] = useState(false);

  const [errors, setErrors] = useState({
    [ECheckoutFieldTypes.FIRST_NAME]: '',
    [ECheckoutFieldTypes.LAST_NAME]: '',
    [ECheckoutFieldTypes.PHONE_NUMBER]: '',
  });

  const [showAgreedError, setAcceptTermsAndConditions] = useState(false);

  const validateNamesAndPhones = () => {
    let valid = true;
    const newErrors = {
      [ECheckoutFieldTypes.FIRST_NAME]: '',
      [ECheckoutFieldTypes.LAST_NAME]: '',
      [ECheckoutFieldTypes.PHONE_NUMBER]: '',
    };

    if (_.isEmpty(firstName.trim()) || firstName.trim() === '') {
      newErrors[ECheckoutFieldTypes.FIRST_NAME] = 'First name is required.';
      valid = false;
    }

    if (_.isEmpty(lastName.trim()) || lastName.trim() === '') {
      newErrors[ECheckoutFieldTypes.LAST_NAME] = 'Last name is required.';
      valid = false;
    }

    if (_.isEmpty(lastName.trim()) || !phoneNumber.match(phoneRegExp)) {
      newErrors[ECheckoutFieldTypes.PHONE_NUMBER] = 'Enter a valid 10-digit phone number.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSaveCardData = useCallback(
    (event) => {
      event.preventDefault();

      // Reset error messages
      setAcceptTermsAndConditions(false);
      setErrors({
        [ECheckoutFieldTypes.FIRST_NAME]: '',
        [ECheckoutFieldTypes.LAST_NAME]: '',
        [ECheckoutFieldTypes.PHONE_NUMBER]: '',
      });

      if (!validateNamesAndPhones()) {
        return;
      }

      if (!acceptTermsAndCondictions) {
        setAcceptTermsAndConditions(true);
        return;
      }

      // Check if Sripe library and stripe elements are loaded
      if (!stripe || !elements) {
        return;
      }

      stripe
        .confirmSetup({
          elements,
          confirmParams: {
            return_url: `http://${getHostname()}${reverse({
              url: URLS.SUCCESS_CHECKOUT,
              params: { storeId, orderId, guestId: userId },
            })}`,
            payment_method_data: {
              billing_details: {
                name: `${firstName} ${lastName}`,
                phone: phoneNumber,
              },
            },
            expand: ['payment_method'],
          },
        })
        .catch((error) => {
          message.error(error.message);
        });
    },
    [acceptTermsAndCondictions, errors, firstName, lastName, phoneNumber, storeId, orderId, userId],
  );

  const handleChangeField = useCallback(({ field, value }: { field: string; value: string }) => {
    switch (field) {
      case ECheckoutFieldTypes.FIRST_NAME:
        setFirstName(value);
        break;
      case ECheckoutFieldTypes.LAST_NAME:
        setLastName(value);
        break;
      case ECheckoutFieldTypes.PHONE_NUMBER:
        setPhoneNumber(value);
        break;
      default:
        break;
    }
  }, []);

  const handleChangeAgreement = useCallback(
    (newValue) => {
      setHasAgreed(newValue);

      if (showAgreedError && newValue) {
        setAcceptTermsAndConditions(false);
      }
    },
    [acceptTermsAndCondictions, showAgreedError],
  );

  return (
    <Card style={{ padding: '20px' }}>
      <form onSubmit={handleSaveCardData} className="StoreCheckout-form">
        <Typography variant="h6" style={{ marginBottom: '8px', fontWeight: 'bold' }}>
          Checkout
        </Typography>
        <div className="StoreCheckout-form_mobile">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextField
                required
                fullWidth
                label="First name"
                onChange={_.debounce(
                  (event) =>
                    handleChangeField({
                      field: ECheckoutFieldTypes.FIRST_NAME,
                      value: event.target.value,
                    }),
                  500,
                )}
                showHelperIcon={false}
                error={!_.isEmpty(errors[ECheckoutFieldTypes.FIRST_NAME])}
                errorMessage={
                  errors[ECheckoutFieldTypes.FIRST_NAME] && (
                    <p className="error">{errors[ECheckoutFieldTypes.FIRST_NAME]}</p>
                  )
                }
              />
            </Col>
            <Col span={12}>
              <TextField
                required
                fullWidth
                label="Last name"
                onChange={_.debounce(
                  (event) =>
                    handleChangeField({
                      field: ECheckoutFieldTypes.LAST_NAME,
                      value: event.target.value,
                    }),
                  500,
                )}
                showHelperIcon={false}
                error={!_.isEmpty(errors[ECheckoutFieldTypes.LAST_NAME])}
                errorMessage={
                  errors[ECheckoutFieldTypes.LAST_NAME] && (
                    <p className="error">{errors[ECheckoutFieldTypes.LAST_NAME]}</p>
                  )
                }
              />
            </Col>
            <Col span={12}>
              <LinkAuthenticationElement />
            </Col>
            <Col span={12}>
              <TextField
                required
                fullWidth
                label="Phone number"
                onChange={_.debounce(
                  (event) =>
                    handleChangeField({
                      field: ECheckoutFieldTypes.PHONE_NUMBER,
                      value: event.target.value,
                    }),
                  500,
                )}
                showHelperIcon={false}
                error={!_.isEmpty(errors[ECheckoutFieldTypes.PHONE_NUMBER])}
                errorMessage={
                  errors[ECheckoutFieldTypes.PHONE_NUMBER] && (
                    <p className="error">{errors[ECheckoutFieldTypes.PHONE_NUMBER]}</p>
                  )
                }
              />
            </Col>
            <Col span={24}>
              <PaymentElement />
            </Col>
          </Row>
          <Agreement onChange={handleChangeAgreement} />
          {showAgreedError && (
            <InputHelperText type="error">
              You must agree to the terms and conditions to proceed.
            </InputHelperText>
          )}
        </div>

        <div className="StoreCheckout-fix-actions">
          <div className="StoreCheckout-fix-actions-content">
            <Button variant="contained" type="submit">
              Place your order
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default CheckoutForm;
