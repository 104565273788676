import { BASE_URL, post, useFetch } from 'utils/sdk';
import { Currency } from './types/store';

export const addProductToCard = ({
  productId,
  quantity,
  deliveryDate,
  guestPrice,
  isGuestPrice,
  extraFields,
  note,
}: {
  note: string;
  productId: number;
  quantity: number;
  deliveryDate: Date;
  guestPrice: number;
  isGuestPrice?: boolean;
  extraFields?: Array<{ productField: number; value: string }>;
}) => {
  return post(`${BASE_URL}services/order/add/`, {
    product_id: productId,
    quantity,
    delivery_date: deliveryDate,
    note,
    guest_price: guestPrice,
    is_guest_price: isGuestPrice,
    extra_fields: extraFields?.map(({ productField, value }) => ({
      product_field_id: productField,
      value,
    })),
  });
};

export const updateOrderProduct = ({
  orderProductId,
  productId,
  quantity,
  deliveryDate,
  guestPrice,
  isGuestPrice,
  extraFields,
  note,
}: {
  orderProductId: number;
  note: string;
  productId: number;
  quantity: number;
  deliveryDate: Date;
  guestPrice: number;
  isGuestPrice?: boolean;
  extraFields?: Array<{ productField: number; value: string }>;
}) => {
  return post(`${BASE_URL}services/orderproduct/${orderProductId}/update/`, {
    product_id: productId,
    quantity,
    delivery_date: deliveryDate,
    note,
    guest_price: guestPrice,
    is_guest_price: isGuestPrice,
    extra_fields: extraFields?.map(({ productField, value }) => ({
      product_field_id: productField,
      value,
    })),
  });
};

export interface IGuestCartDetail {
  id: number;
  guest_id: number;
  guest_name?: string;
  guest_email?: string;
  guest_phone?: string;
  store_id: number;
  store_name: string;
  currency: Currency;
  is_locked: boolean;
  total_amount: number;
  total_convenience_fees: number;
  guest_total: number;
  order_product_quantity: number;
  order_products: Array<{
    id: number;
    quantity: number;
    price: string;
    amount: number;
    convenience_fee: number;
    guest_amount: number;
    delivery_date: Date;
    note: string;
    is_preorder: boolean;
    product: {
      id: number;
      quantity: number;
      has_quantity: number;
    };
    product_info: {
      product_name: string;
      price: number;
      image: string;
      is_guest_price: boolean;
      requires_approval: boolean;
    };
    extra_field_values?: Array<{
      label: string;
      value: string;
      product_field_id: number;
    }>;
  }>;
}

export const useGuestCartDetail = ({ storeId }: { storeId: number }) =>
  useFetch<IGuestCartDetail>(`services/guest/store-cart/${storeId}/detail/`);

export interface IGuestCheckoutStoreSummary {
  id: number;
  guest_id: number;
  guest_name?: string;
  guest_email?: string;
  guest_phone?: string;
  store_id: number;
  store_name: string;
  currency: Currency;
  is_locked: boolean;
  total_amount: number;
  total_convenience_fees: number;
  guest_total: number;
  order_product_quantity: number;
}

export const useGuestCheckoutStoreSummary = ({
  storeId,
  orderId,
  enabled,
}: {
  storeId: number;
  orderId: number;
  enabled;
}) =>
  useFetch<IGuestCheckoutStoreSummary>(
    enabled
      ? `services/guest/store-checkout-summary/${storeId}/order/${orderId}/detail/`
      : undefined,
  );
