import React, { useCallback } from 'react';
import { Button, Dropdown, Menu } from 'ui-kit';
import { ChevronDown as ChevronDownIcon } from 'react-feather';
import { PurchaseStatus } from 'api/types/order';
import { getLocalDatetime } from 'utils/datetime';
import { exportOrderProducts, exportOrders } from 'api/order_sdk';
import { downloadListOfListsAsCSV } from 'utils/csv';
import { EXPORT_ORDER_PRODUCTS_COLUMNS, EXPORT_ORDERS_COLUMNS } from './constants';
import { EDateFilter } from 'pages/OrdersList/constants';

export interface IOrdersExportButton {
  selectedFilters: {
    selectedStore?: number;
    selectedStatus?: PurchaseStatus;
    startDate?: Date;
    endDate?: Date;
    dateFilter?: EDateFilter;
  };
  disabled: boolean;
}

const OrdersExportButton = ({ selectedFilters, disabled }: IOrdersExportButton) => {
  const handleExportOrders = useCallback(
    () =>
      exportOrders({
        storeId: selectedFilters.selectedStore,
        status: selectedFilters.selectedStatus,
        startDate: selectedFilters.startDate
          ? getLocalDatetime(selectedFilters.startDate, 'YYYY-MM-DD')
          : undefined,
        endDate: selectedFilters.endDate
          ? getLocalDatetime(selectedFilters.endDate, 'YYYY-MM-DD')
          : undefined,
        dateFilter: selectedFilters.dateFilter,
      }).then((res) => {
        const dataForCSV = [
          EXPORT_ORDERS_COLUMNS,
          ...res.map((order) => [
            order.guest_name,
            order.date_created,
            order.total_amount,
            order.total_owner_amount,
            order.order_number,
            order.store_name,
          ]),
        ];

        downloadListOfListsAsCSV({
          data: dataForCSV,
          filename: 'order_sales.csv',
        });
      }),
    [selectedFilters],
  );

  const handleExportProducts = useCallback(
    () =>
      exportOrderProducts({
        storeId: selectedFilters.selectedStore,
        status: selectedFilters.selectedStatus,
        startDate: selectedFilters.startDate
          ? getLocalDatetime(selectedFilters.startDate, 'YYYY-MM-DD')
          : undefined,
        endDate: selectedFilters.endDate
          ? getLocalDatetime(selectedFilters.endDate, 'YYYY-MM-DD')
          : undefined,
        dateFilter: selectedFilters.dateFilter,
      }).then((res) => {
        const dataForCSV = [
          EXPORT_ORDER_PRODUCTS_COLUMNS,
          ...res.map((product) => [
            product.guest_name,
            product.product_name,
            product.date_created,
            product.delivery_date,
            product.total_amount,
            product.your_profit,
            product.order_number,
            product.store_name,
          ]),
        ];

        downloadListOfListsAsCSV({
          data: dataForCSV,
          filename: 'product_sales.csv',
        });
      }),
    [selectedFilters],
  );

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={handleExportOrders}>Orders (CSV)</Menu.Item>
          <Menu.Item onClick={handleExportProducts}>Products (CSV)</Menu.Item>
        </Menu>
      }
      disabled={disabled}
    >
      <Button variant="contained" endIcon={<ChevronDownIcon />}>
        Export
      </Button>
    </Dropdown>
  );
};

export default OrdersExportButton;
