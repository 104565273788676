import React from 'react';
import { Typography } from 'antd';
import Layout from 'components/StoreLayout';
import './styles.scss';
import { Button, Link } from 'ui-kit';
import { useParams } from 'react-router-dom';

const FailureCheckoutPage = () => {
  const { storeId } = useParams<{
    storeId: string;
  }>();

  return (
    <div className="StoreCheckoutFailure">
      <Layout className="">
        <Layout.Content className="StoreCheckoutFailure_layout">
          <div className="StoreCheckoutFailure_content">
            <Typography.Title className="StoreCheckoutFailure_title" level={1}>
              Something went wrong.
            </Typography.Title>

            <Typography.Paragraph className="StoreCheckoutFailure_text">
              An error occurred while processing your payment. If you have any problems, please let
              us know,{' '}
              <Link target="_blank" href="mailto:support@thehost.co">
                support@thehost.co
              </Link>
              !
            </Typography.Paragraph>
            {storeId && (
              <Button variant="contained" to={`/store/${storeId}/`}>
                Back to store
              </Button>
            )}
          </div>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default FailureCheckoutPage;
