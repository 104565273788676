import React from 'react';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils';

import { Button, Typography } from 'ui-kit';
import { PurchaseStatus } from 'api/types/order';
import { reverse } from 'utils/urls';
import { URLS } from 'components/Routes/urls';
import RefundButton from 'components/RefundButton';
import { ERefundType } from 'components/RefundButton/contants';

export const GUEST_NAME_COLUMN = {
  title: 'Guest Name',
  dataIndex: 'guest_name',
  key: 'guest_name',
  render: (text) => (
    <Typography variant="body1" className="guest-name-cell">
      {text}
    </Typography>
  ),
};

export const DATE_OF_PURCHASE_COLUMN = {
  title: 'Date of Purchase',
  dataIndex: 'date_created',
  key: 'date_created',
  render: (text) => <Typography variant="body2">{dayjs(text).format('Do MMMM YYYY')}</Typography>,
};

export const GUEST_PAID_COLUMN = {
  title: 'Guest Paid',
  dataIndex: 'grand_total',
  key: 'grand_total',
  render: (text, order) => (
    <Typography variant="body2">{formatCurrency(text, order.currency)}</Typography>
  ),
};

export const YOUR_PROFIT_COLUMN = {
  title: 'Your Profit',
  dataIndex: 'your_profit',
  key: 'your_profit',
  render: (text, order) => (
    <Typography variant="body2">
      {formatCurrency(order.status === PurchaseStatus.CANCEL ? '0.00' : text, order.currency)}
    </Typography>
  ),
};

export const getActionColumn = ({ onRefundCompleted }: { onRefundCompleted: () => void }) => ({
  title: 'Actions',
  key: 'actions',
  render: (text, order) => {
    if (order.status === PurchaseStatus.HOLD) {
      return (
        <Button
          variant="outlined"
          size="small"
          href={reverse({
            url: URLS.OWNER_MANAGE_ORDER,
            params: {
              ownerOrderUuid: order.owner_order_uuid,
            },
          })}
        >
          Approve or Deny
        </Button>
      );
    }
    return (
      <RefundButton
        nonRefundableReason={order.non_refundable_reason}
        disabled={!order.is_refundable}
        title="Refund order"
        refundType={ERefundType.ORDER}
        order={order}
        onRefundCompleted={onRefundCompleted}
      />
    );
  },
});
