import { OwnerNameSnippet } from './owner';
import { Payment, PayoutSnippet } from './payout';
import { OrderProduct } from './product';
import { CountryCode, Currency, StoreSnippet } from './store';

export enum PurchaseStatus {
  PENDING = 'PEND',
  PROCESSING = 'PROC',
  COMPLETED = 'COMP',
  HOLD = 'HOLD',
  CANCEL = 'CNCL',
  REFUNDED = 'RFND',
}

export const PurchaseStatusMap = {
  [PurchaseStatus.PENDING]: 'Pending',
  [PurchaseStatus.PROCESSING]: 'Processing',
  [PurchaseStatus.COMPLETED]: 'Completed',
  [PurchaseStatus.HOLD]: 'Needs approval',
  [PurchaseStatus.CANCEL]: 'Denied',
  [PurchaseStatus.REFUNDED]: 'Refunded',
};

export enum EPurchaseCancelReason {
  OWNER = 'OWNR',
  USER = 'USER',
}

export const PurchaseCancelReasonMap = {
  [EPurchaseCancelReason.OWNER]: 'Owner',
  [EPurchaseCancelReason.USER]: 'User',
};

export interface GuestSnippet {
  actor: number;
  email: string;
  firstName: string;
  id: number;
  image?: string;
  isAnonymous: boolean;
  lastName: string;
}

export interface Order {
  approvalNote?: string;
  cancelReason?: string;
  city?: string;
  country: CountryCode;
  currency: Currency;
  dateCreated: string;
  dateModified: string;
  email: string;
  fax?: string;
  grandTotal: string;
  guest: GuestSnippet;
  guestName: string;
  guestOrderUuid: string;
  id: number;
  mobile?: string;
  orderNumber: string;
  owner: OwnerNameSnippet;
  ownerName: string;
  ownerOrderUuid: string;
  payment: Payment;
  payout: PayoutSnippet;
  phone: string;
  postalCode?: string;
  products?: OrderProduct[];
  state?: string;
  status: PurchaseStatus;
  store: StoreSnippet;
  storeName: string;
  street1?: string;
  street2?: string;
  totalAmount: string;
  totalFees: string;
  totalHostAmount: string;
  totalLocalAmount: string;
  totalMerchantAmount: string;
  totalOwnerAmount: string;
  totalTaxableAmount: string;
  totalTaxes: string;
  transactionFees: string;
}

export interface OrderSnippet {
  currency: Currency;
  dateCreated: string;
  grandTotal: string;
  guestId: number;
  id: number;
  orderNumber: string;
  ownerId: number;
  status: PurchaseStatus;
  storeId: number;
  totalAmount: string;
  totalFees: string;
  totalHostAmount: string;
  totalLocalAmount: string;
  totalMerchantAmount: string;
  totalownerAmount: string;
  totalTaxes: string;
}
