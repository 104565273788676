import React from 'react';
import { IOrder } from 'api/order_sdk';
import { formatCurrency } from 'utils';
import dayjs from 'dayjs';

import Table from 'components/Table';
import { Button, Tooltip, Typography } from 'ui-kit';
import RefundButton from 'components/RefundButton';
import { ERefundType } from 'components/RefundButton/contants';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { PurchaseCancelReasonMap, PurchaseStatus, PurchaseStatusMap } from 'api/types/order';
import { reverse } from 'utils/urls';
import { URLS } from 'components/Routes/urls';
import { useHistory } from 'react-router-dom';

export interface IOrderProductsTable {
  order: IOrder;
  onRefundCompleted: () => void;
}

const OrderProductsTable = ({ order, onRefundCompleted }: IOrderProductsTable) => {
  const history = useHistory();

  return (
    <Table
      rowKey="id"
      columns={[
        { title: 'Product Name', dataIndex: 'product_name', key: 'productName' },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
        },
        {
          title: 'Date of Service',
          dataIndex: 'delivery_date',
          key: 'deliveryDate',
          render: (text) => {
            if (text) {
              return <Typography variant="body2">{dayjs(text).format('Do MMMM YYYY')}</Typography>;
            }
            return <Typography variant="body2">n/a</Typography>;
          },
        },
        {
          title: 'Price Per Item',
          key: 'price',
          dataIndex: 'price',
          render: (text, record) => (
            <Typography variant="body2">{formatCurrency(text, record.currency)}</Typography>
          ),
        },
        {
          title: 'Is Refunded',
          key: 'is_refunded',
          dataIndex: 'is_refunded',
          render: (text) =>
            text ? (
              <CheckCircleOutlined style={{ color: 'green', fontSize: '14px' }} />
            ) : (
              <CloseCircleOutlined
                style={{
                  color: 'red',
                  fontSize: '14px',
                }}
              />
            ),
        },
        // TODO: rename to purchases statuses
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text, product) => (
            <Typography variant="body2">
              {PurchaseStatusMap[text]}{' '}
              {text === PurchaseStatus.CANCEL && (
                <Tooltip
                  title={`The order is denied by ${PurchaseCancelReasonMap[product.cancel_reason]}`}
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              )}
            </Typography>
          ),
        },
        {
          title: 'Actions',
          key: 'actions',
          render: (product) => {
            if (product.status === PurchaseStatus.HOLD) {
              return (
                <Tooltip
                  title={
                    product.can_approve
                      ? ''
                      : 'This product can be approved only from the merchant side'
                  }
                >
                  <span>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={!product.can_approve}
                      onClick={() =>
                        history.push(
                          reverse({
                            url: URLS.OWNER_MANAGE_ORDER,
                            params: {
                              ownerOrderUuid: product.owner_purchase_uuid,
                            },
                          }),
                        )
                      }
                    >
                      Approve or Deny
                    </Button>
                  </span>
                </Tooltip>
              );
            }
            return (
              <RefundButton
                nonRefundableReason={product.non_refundable_reason}
                disabled={!product.is_refundable}
                title="Refund item"
                refundType={ERefundType.ORDER_PRODUCT}
                order={order}
                product={product}
                onRefundCompleted={onRefundCompleted}
              />
            );
          },
        },
      ]}
      dataSource={order.products}
      pagination={false}
      className="DashboardSales_subtable"
    />
  );
};

export default OrderProductsTable;
