export enum URLS {
  ACCOUNT = '/overview/account/:tab',
  ORDERS = '/overview/orders/',
  INTEGRATION = '/overview/partners/:company/',
  VENDORS = '/vendors/',
  OWNER_MANAGE_ORDER = '/owner/:ownerOrderUuid/?showPrices=True', // The owner always sees the prices
  CHECKOUT = '/store/:storeId/checkout/:orderId/:customerId/',
  CART_DETAILS = '/store/:storeId/cart/',
  SUCCESS_CHECKOUT = '/store/:storeId/checkout/success/:orderId/:guestId/',
  FAILURE_CHECKOUT = '/store/:storeId/checkout/failure/:orderId/:guestId/',
}
