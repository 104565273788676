import React, { useMemo } from 'react';
import { Row, Col, Divider } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { Typography } from 'ui-kit';

import OwnerProfitTooltip from 'ui-kit/OwnerProfitTooltip';

import { PurchaseStatus, PurchaseStatusMap } from 'api/types/order';
import { PaymentPlan } from 'api/types/owner';
import { IPurchaseDetail, ActorType } from 'api/purchase_sdk';
import { formatCurrency } from 'utils';

import './PurchaseDetailsTable.scss';

dayjs.extend(advancedFormat);

const getFields = (purchase: IPurchaseDetail, actorType: ActorType, showPrices: boolean) => {
  return [
    { label: 'Store Name', value: purchase.store_name },
    { label: 'Guest', value: purchase.guest },
    { label: 'Guest Email', value: purchase.guest_email },
    { label: 'Guest Phone', value: purchase.guest_phone },
    {
      label: 'Amount',
      value: formatCurrency(purchase.guest_amount, purchase.currency),
      canShow: showPrices,
    },
    {
      label: 'Your profit',
      value: formatCurrency(purchase.owner_amount, purchase.currency),
      canShow: actorType === 'owner' && showPrices,
    },
    {
      label: 'Your Profit',
      value: formatCurrency(purchase.merchant_amount, purchase.currency),
      canShow: actorType === 'merchant' && showPrices,
    },
    { label: 'Status', value: PurchaseStatusMap[purchase.status] },
  ];
};

export interface IPurchaseDetailsTable {
  purchase: IPurchaseDetail;
  actorType: ActorType;
  showPrices: boolean;
}

const PurchaseDetailsTable = ({ purchase, actorType, showPrices }: IPurchaseDetailsTable) => {
  const showOwnerProfitTooltip = useMemo(
    () => purchase.owner_plan === PaymentPlan.Basic,
    [purchase],
  );

  const fields = getFields(purchase, actorType, showPrices);
  return (
    <div className="OrderInfo">
      {fields.map(
        ({ label, value, canShow = true }) =>
          canShow && (
            <div key={label} className="row">
              <Typography className="label" variant="subtitle1">
                {label}
              </Typography>
              <Typography className="value" variant="body1">
                {value}
                {label === 'Your profit' && showOwnerProfitTooltip && <OwnerProfitTooltip />}
              </Typography>
            </div>
          ),
      )}
      <div className="products-table">
        <Row className="product-info-row product-table-header">
          <Col className="product-column image-column">
            <Typography variant="subtitle2" className="columm-header">
              Image
            </Typography>
          </Col>
          <Col className="product-column">
            <Typography variant="subtitle2" className="columm-header">
              Product
            </Typography>
          </Col>
          <Col className="product-column">
            <Typography variant="subtitle2" className="columm-header">
              Quantity
            </Typography>
          </Col>
          <Col className="product-column">
            <Typography variant="subtitle2" className="columm-header">
              Date of Service
            </Typography>
          </Col>
          {showPrices && (
            <Col className="product-column">
              <Typography variant="subtitle2" className="columm-header">
                Price per Item
              </Typography>
            </Col>
          )}
        </Row>
        <Divider className="table-divider" />
        {purchase?.order_products?.map(
          (
            {
              id,
              product_name,
              quantity,
              price,
              delivery_date,
              note,
              product_info,
              extra_field_values,
            },
            index,
          ) => (
            <div key={id} className="product">
              <Row className="product-info-row">
                <Col className="product-column">
                  <img src={product_info.image} />
                </Col>
                <Col className="product-column">
                  <Typography variant="body2">{product_name}</Typography>
                </Col>
                <Col className="product-column">
                  <Typography variant="body2">{quantity}</Typography>
                </Col>
                <Col className="product-column">
                  <Typography variant="body2">
                    {delivery_date && dayjs(delivery_date).format('Do MMMM YYYY')}
                    {!delivery_date && 'N/A'}
                  </Typography>
                </Col>
                {showPrices && (
                  <Col className="product-column">
                    <Typography variant="body2">
                      {formatCurrency(price, purchase.currency)}
                    </Typography>
                  </Col>
                )}
              </Row>
              {note && (
                <Row className="product-info-row note-row">
                  <Col className="product-extra-info-column">
                    <Typography variant="subtitle2" className="note">
                      Guest Note
                    </Typography>
                  </Col>
                  <Col className="product-note">
                    <Typography variant="body2">{note}</Typography>
                  </Col>
                </Row>
              )}
              {extra_field_values?.map(({ label, value }, index) => (
                <Row key={index} className="product-info-row note-row">
                  <Col className="product-extra-info-column">
                    <Typography variant="body2" className="note">
                      {label}
                    </Typography>
                  </Col>
                  <Col className="product-note">
                    <Typography variant="body2">{value}</Typography>
                  </Col>
                </Row>
              ))}
              {/* TODO: update this style if we support requests w/ multiple products */}
              {index === purchase.order_products.length - 1 && purchase.approval_note && (
                <Row className="product-info-row note-row">
                  <Col className="product-extra-info-column">
                    <Typography variant="body2" className="note">
                      {purchase.status === PurchaseStatus.COMPLETED
                        ? 'Approval Note'
                        : 'Guest Note'}
                    </Typography>
                  </Col>
                  <Col className="product-note">
                    <Typography variant="body2">{purchase.approval_note}</Typography>
                  </Col>
                </Row>
              )}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default PurchaseDetailsTable;
